<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../../assets/left.png" alt @click="goBack" />
        <span class="center">合同管理</span>
        <img src="../../../assets/right.png" alt />
        <span class="center">我的合同</span>
        <img src="../../../assets/right.png" alt />
        <span class="center">编辑合同信息</span>
      </div>
      <el-button @click="saveContract" size="small" type="primary" icon="el-icon-plus" class="top_btn">保存</el-button>
    </div>
    <el-card class="box-card">
      <div style="margin-top: 10px">
        <el-card class="box-card">
          <div class="left_top">
            <div class="left_icon">
              <span></span>
              <span>合同基本信息</span>
            </div>
            <div class="right_icon">
              <span></span>
            </div>
          </div>
          <div class="card-body">
            <el-row>
              <el-col :span="8" class="text-align">
                合同名称：
                <div>
                  <span>*</span>
                  <el-input v-model="detailInfo.name" style="width: 90%"></el-input>
                </div>
              </el-col>
              <el-col :span="8" class="text-align">
                合同分类：
                <div>
                  <el-select v-model="detailInfo.type" style="width: 90%" clearable>
                    <el-option label="劳动合同" value="1"></el-option>
                    <el-option label="其他" value="2"></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="8" class="text-align">
                合同签署截止日期：
                <div>
                  <el-date-picker type="date" :picker-options="pickerOptions" :editable="false" :clearable="false"
                    value-format="yyyy-MM-dd HH:mm:ss" v-model="detailInfo.signDeadline" style="width: 90%">
                  </el-date-picker>
                </div>
              </el-col>
              <el-col :span="8" class="text-align">
                甲方（当前企业）签署人：
                <div>
                  <el-select v-model="subjectAccounts[0]" style="width: 90%" @change="jiaChange">
                    <el-option v-for="item in userList" :key="item.value" :label="item.label + '-' + item.idCardNumber"
                      :value="item.value"></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="8" class="text-align">
                乙方（员工）签署人：
                <div>
                  <el-select v-model="subjectAccounts[1]" style="width: 90%" filterable clearable @change="yiChange">
                    <el-option v-for="item in userList" :key="item.value" :label="item.label + '-' + item.username"
                      :value="item.value"></el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>

        <!-- <el-card class="box-card">
          <div class="left_top">
            <div class="left_icon"><span></span> <span>合同签署人</span></div>

            <span slot="right">
              <el-button
                type="text"
                class="el-icon-plus"
                @click="addSubject()"
                v-if="isShowAddSigner"
                >新增签署方</el-button
              >
            </span>
          </div>

          <div class="card-body" id="singerTable">
            <el-table :data="subjects" style="width: 100%">
              <el-table-column prop="role" label="签署方" width="180">
                <template slot-scope="scope">
                  {{ scope.$index | signTypeVal }}
                </template>
              </el-table-column>
              <el-table-column label="签署主体" width="500">
                <template slot-scope="scope">
                  <SelectSubject
                    :value="scope.row"
                    @change="subjectChange($event)"
                  ></SelectSubject>
                </template>
              </el-table-column>
              <el-table-column label="签署人" prop="signerName">
                <template slot-scope="scope">
                  <MySelect
                    filterable
                    remote
                    reserve-keyword
                    v-model="scope.row.signerAccount"
                    placeholder="请选择"
                    @change="singerAccountChange(scope.row)"
                    @focus="subjectChange(scope.row)"
                    @onRmoteSearch="subjectChange(scope.row, $event)"
                    :disabled="scope.row.subjectType === '1'"
                  >
                    <el-option
                      v-for="(item, index) in scope.row.signerAccountOptions"
                      :key="index"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </MySelect>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    style="color: #f56c6c"
                    @click="deleteSigner(scope.row)"
                    >删除签署方</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>-->
        <!-- 选择合同附件 -->
        <!-- <Divider :title="'合同附件'"> -->
        <el-card class="box-card">
          <div class="left_top">
            <div class="left_icon">
              <span></span>
              <span>合同附件</span>
            </div>

            <span slot="right">
              <UploadButton accept="application/pdf" path="/contract.upload.json" @change="uploadComplete($event)"
                style="margin-right: 10px" />
              <el-button type="text" class="el-icon-edit" @click="setSignSeal">指定签署位置</el-button>
            </span>
          </div>

          <div class="card-body">
            <div v-for="(item, i) in files" :key="i">
              <span>附件{{ i + 1 }}：</span>
              <p style="display: inline-block; min-width: 350px">{{ item.name }}</p>
              <a style="color: #409eff; padding-right: 10px; cursor: pointer" @click="delFile(item)">删除</a>
              <a style="color: #409eff" :href="item.url" target="_blank">预览</a>
            </div>
            <div style="text-align: center">
              <commonEmpty :emptyMessage="'暂无合同附件'" v-if="files.length === 0"></commonEmpty>
            </div>
          </div>
        </el-card>
      </div>
    </el-card>
  </div>
</template>

<script>
import commonEmpty from '/src/components/common-empty/common-empty';
import { api } from '/src/api/base';
import { parseFilePath } from '/src/lib/dev';
const ContractDetail = api('/contract')('contract.describe.json');
// const userDescribe = api("/user")("user.describe.json");
const ContractEdit = api('/contract')('contract.edit.json');
export const EnterpriseSimpleList = api()('enterprise.simple.list.json');
export const UserSimpleList = api()('user.simple.list.json');

export default {
  components: {
    commonEmpty,
    UploadButton: () => import('/src/components/upload-button.vue'),
    // MySelect: () => import('../mySelect.vue'),
    // SelectSubject: () => import('../selectSubject.vue'),
  },
  data () {
    return {
      files: [],
      pickerOptions: {                                 //禁用当前日期之前的日期
        disabledDate (time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      detailInfo: {
        name: '合同',
        type: '',
        signDeadline: '2021-03-25 12:00:00',
      },
      templateContent: '',
      ruleForm: {
        name: '',
      },
      rules: {
        name: [],
      },
      code: '',
      isShowAddSigner: true,
      subjects: [],
      userList: [],
      // enterpriseList: [],
      loading: false,
      subjectAccounts: []
    };
  },
  async created () {
    this.code = this.$route.query.code;
    await this.getDetail();
  },
  async mounted () {
    // await this.getEnterpriseList()
    await this.getUserList()
  },
  methods: {
    test ($event, row) {
      console.log($event, row);
    },
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
    },
    goBack () {
      window.history.go(-1);
    },
    // async getEnterpriseList () {
    //   const enterprises = await EnterpriseSimpleList({
    //     pageNumber: 1,
    //     pageSize: 100,
    //     authenticateState: '3',
    //     $ignoreRepeat: true
    //   });
    //   this.enterpriseList = enterprises.list.map(it => {
    //     return {
    //       ...it,
    //       label: it.name,
    //       value: it.code
    //     };
    //   });
    // },
    async getUserList () {
      const users = await UserSimpleList({
        pageNumber: 1,
        pageSize: 1000,
        isAuthenticated: true,
        $ignoreRepeat: true
      });
      this.userList = users.list.map(it => {
        return {
          ...it,
          label: it.name,
          value: it.code
        };
      });
    },
    async getDetail () {
      const res = await ContractDetail({ code: this.code });
      this.detailInfo = res;
      if (res.filePaths) {
        this.files = (res.filePaths.split(',') || []).map(parseFilePath);
      }
      if (!res.subjectAccounts) {
        res.subjectAccounts = localStorage.getItem('enterprise') + ','
        res.subjectNames = localStorage.getItem('enterpriseName') + ','
        res.subjectTypes = '1,2'
        // res.signerAccounts = localStorage.getItem('code') + ','
        // res.signerNames = localStorage.getItem('name') + ','
        this.subjectAccounts = []
      } else {
        this.subjectAccounts = res.signerAccounts.split(',')
      }
      this.subjects = this.parseSigner(res);
    },

    parseSigner (data) {
      const subjectAccounts = data.subjectAccounts.split(',')
      const subjectNames = data.subjectNames.split(',')
      const subjectTypes = data.subjectTypes.split(',')
      const signerAccounts = data.signerAccounts.split(',')
      const signerNames = data.signerNames.split(',')
      // const len = subjectAccounts.length;
      // if (
      //   subjectNames.length != len ||
      //   subjectTypes.length != len ||
      //   signerAccounts.length != len ||
      //   signerNames.length != len
      // ) {
      //   alert('签署人配置有误');
      // } else {
      return subjectAccounts.map((it, index) => {
        return {
          subjectAccount: it,
          subjectName: subjectNames[index],
          subjectType: subjectTypes[index],
          signerAccount: signerAccounts[index],
          signerName: signerNames[index],
          signerAccountOptions: [
            {
              code: signerAccounts[index],
              name: signerNames[index],
            },
          ],
        };
      });
      // }
    },
    jiaChange () {
      this.subjects[0].signerAccount = this.subjectAccounts[0]
      this.subjects[0].signerName = this.userList.find(it => it.code === this.subjectAccounts[0]).name
      this.subjects[0].signerAccountOptions = [{
        code: this.subjectAccounts[0],
        name: this.userList.find(it => it.code === this.subjectAccounts[0]).name,
      }]
    },
    yiChange () {
      this.subjects[1].subjectAccount = this.subjectAccounts[1]
      this.subjects[1].subjectName = this.userList.find(it => it.code === this.subjectAccounts[1]).name
      this.subjects[1].signerAccount = this.subjectAccounts[1]
      this.subjects[1].signerName = this.userList.find(it => it.code === this.subjectAccounts[1]).name
      this.subjects[1].signerAccountOptions = [{
        code: this.subjectAccounts[1],
        name: this.userList.find(it => it.code === this.subjectAccounts[1]).name,
      }]
    },
    close () {
      this.getDetail();
    },
    // addSubject () {
    //   this.subjects.push({
    //     subjectType: '2',
    //     subjectAccount: null,
    //     subjectName: null,
    //     signerName: null,
    //     signerAccount: null,
    //     signerAccountOptions: [],
    //   });
    //   if (this.tableData.length === 4) this.isShowAddSigner = false;
    // },
    deleteSigner (row) {
      if (this.subjects.length <= 2) {
        this.$message.error('签署方至少两方');
      } else {
        this.subjects = this.subjects.filter((it) => it != row);
        this.$message.success('成功删除！');
      }
    },
    singerAccountChange (row) {
      const target = row.signerAccountOptions.find(
        (it) => it.code === row.signerAccount,
      );
      if (target) {
        row.signerName = target.name;
      }
    },
    async subjectChange (row, keywords) {
      if (row.subjectType === '1') {
        if (!row.subjectAccount) {
          row.signerAccount = null;
          return;
        }
        const res = await UserSimpleList({
          enterprise: row.subjectAccount,
          pageSize: 10,
          pageNumber: 1,
          isAuthenticated: true,
          keywords: keywords,
        });
        row.signerAccountOptions = res.list;
      } else {
        row.signerName = row.subjectName;

        row.signerAccount = row.signerName;
        console.log(row);
        row.signerAccountOptions = [
          {
            code: row.subjectAccount,
            name: row.subjectName,
          },
        ];
      }
    },
    tableNull () {
      return this.subjects.every((v) => v.subjectAccount && v.subjectName);
    },
    async saveContract () {
      console.log(this.subjects, 'this.subjects888');
      // if (!this.tableNull()) {
      //   return console.log(this.subjects, '2222');
      // }
      // if () {
      //   return this.$message.error('请选择签署人信息！')
      // }
      const valid = [[this.subjectAccounts.length === 2, '请选择签署人信息！'],
      [this.detailInfo.name, '请填写合同名称'],
      [this.detailInfo.type, '请选择合同分类'],
      [this.detailInfo.signDeadline, '请选择截止日期'],

      ]
      if (!valid.every(([res, message]) => { !res && this.$message.error({ message: message, duration: 3000 }); return res })) return
      // console.log({
      //   subjectAccounts: this.subjects.map((it) => it.subjectAccount).join(','),
      //   subjectNames: this.subjects.map((it) => it.subjectName).join(','),
      //   subjectTypes: this.subjects.map((it) => it.subjectType).join(','),
      //   signerAccounts: this.subjects.map((it) => it.signerAccount).join(','),
      //   signerNames: this.subjects.map((it) => it.signerName).join(','),
      // });

      await ContractEdit({
        code: this.code,
        subjectAccounts: this.subjects.map((it) => it.subjectAccount).join(','),
        subjectNames: this.subjects.map((it) => it.subjectName).join(','),
        subjectTypes: this.subjects.map((it) => it.subjectType).join(','),
        signerAccounts: this.subjects.map((it) => it.signerAccount).join(','),
        signerNames: this.subjects.map((it) => it.signerName).join(','),
        creatorAccount: window.localStorage.getItem('code'),
        creatorName: window.localStorage.getItem('name'),
        filePaths: this.files.map((it) => it.url).join(','),
        name: this.detailInfo.name,
        type: this.detailInfo.type,
        signDeadline: this.detailInfo.signDeadline,
      });
      this.$message.success('编辑成功');
    },
    setSignSeal () {
      this.$router.push({
        path: '/system/contract/pdf',
        query: this.$route.query,
      });
    },
    async uploadComplete (file) {
      this.files.push(parseFilePath(file));
      await this.saveContract()
    },
    delFile (item) {
      this.files = this.files.filter((it) => it != item);
    },
  },
  filters: {
    signTypeVal (val) {
      const map = ['甲方', '乙方', '丙方', '丁方'];
      return map[val] || '甲方';
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0;
}

.card-header {
  display: flex;
  font-size: 14px;
  background: #f7f7f7;
  padding: 8px 20px;
  margin-bottom: 12px;
  align-items: center;
  justify-content: space-between;
}

.card-icon {
  display: flex;
  width: 4px;
  height: 15px;
  background: #659fe5;
  margin-top: 3px;
  margin-right: 10px;
}

.card-title {
  margin-right: 8px;
}

.card-body {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
  font-size: 14px;
  color: #303133;
}

.card-footer {
  margin: 20px 0px;
  text-align: right;
}

.el-table th {
  background-color: white !important;
}
</style>
