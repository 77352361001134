<template>
  <div>
    <img
      src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      alt=""
    />
    <div style="font-size:14px;color:#888">{{ emptyMessage }}</div>
  </div>
</template>
<script>
export default {
  props: {
    emptyMessage: {
      type: String,
      required: true
    }
  }
};
</script>
<style scoped></style>
