import XLSX from 'xlsx';
// import axios from "axios";
// import * as JSZip from "jszip";
// import config from "../config/config";
import { get, isFunction, isString } from 'lodash';
// import Vue from "vue";

// const instance = axios.create({
//   baseURL: config.baseUrl,
//   timeout: 20000
// });

// const shake = (time = 500) => {
//   var timer = 0;
//   return fn => {
//     clearTimeout(timer);
//     timer = setTimeout(fn, time);
//   };
// };

// const shake401 = shake();
// const shake403 = shake();
// instance.interceptors.response.use(response => {
//   if (response.data.code === 401) {
//     shake401(() => {
//       Vue.prototype
//         .$confirm("登录过期，请重新登录", "提示", {
//           confirmButtonText: "确定",
//           cancelButtonText: "取消",
//           type: "warning"
//         })
//         .then(() => {
//           window.location.href =
//             process.env.VUE_APP_PREFIX + "/login?from=" + window.location.href;
//         });
//     });
//   }
//   if (response.data.code === 403) {
//     shake403(() => {
//       Vue.prototype.$confirm("没有权限，请联系管理员", "提示", {
//         confirmButtonText: "确定",
//         cancelButtonText: "取消",
//         type: "warning"
//       });
//     });
//   }
//   return response;
// });

// /**
//  * 登录后种下refreshToken
//  */

// export const getAccessToken = (() => {
//   let accessToken;
//   let expire = 0;
//   return async () => {
//     const now = new Date();
//     if (now > expire) {
//       const refreshToken = window.localStorage.getItem("refreshToken");
//       const currentPath = window.location.href;
//       if (refreshToken) {
//         const refreshResult = await instance
//           .post("/action/common/RefreshToken.json", {
//             refreshToken: refreshToken || ""
//           })
//           .then(it => it.data);
//         if (refreshResult.code === 405) {
//           window.location.href = "/login?relogin=1&from=" + currentPath;
//           throw new Error("needLogin");
//         }
//         if (refreshResult.code === "200") {
//           window.localStorage.setItem(
//             "refreshToken",
//             refreshResult.data.refreshToken
//           );
//           accessToken = refreshResult.data.accessToken;
//           expire = now + 15 * 60 * 1000;
//         }
//       } else {
//         window.location.href = "/login?relogin=1&from=" + currentPath;
//         throw new Error("needLogin");
//       }
//     }
//     return accessToken;
//   };
// })();

// export const doRemoteAction = async (
//   actionName = "",
//   params = {},
//   withAuthorization = true
// ) => {
//   const token = withAuthorization ? window.localStorage.getItem("token") : null;
//   return instance
//     .post(`/${actionName}.json`, params, {
//       headers: token ? { Authorization: token } : {}
//     })
//     .then(it => it?.data?.data || it?.data);
// };
// // 用户
// export const doUserRemoteAction = async (
//   actionName = "",
//   params = {},
//   withAuthorization = true
// ) => {
//   const token = withAuthorization ? window.localStorage.getItem("token") : null;
//   return instance
//     .post(`/user/${actionName}.json`, params, {
//       headers: token ? { Authorization: token } : {}
//     })
//     .then(it => it?.data?.data || it?.data);
// };
// // 职位任务
// export const doJobRemoteAction = async (
//   actionName = "",
//   params = {},
//   withAuthorization = true
// ) => {
//   const token = withAuthorization ? window.localStorage.getItem("token") : null;
//   return instance
//     .post(`/job/${actionName}.json`, params, {
//       headers: token ? { Authorization: token } : {}
//     })
//     .then(it => it?.data?.data || it?.data);
// };

// export const api = (path, withAuthorization = true) => (params = {}) => {
//   return doRemoteAction(path, params, withAuthorization);
// };

// export const getDictionaryByGroupName = (() => {
//   const cache = {};
//   return groupName =>
//     new Promise(resolve => {
//       if (cache[groupName]) {
//         resolve(cache[groupName]);
//       } else {
//         doRemoteAction("dictionary.list", { groupName }).then(res => {
//           cache[groupName] = res[0];
//           resolve(cache[groupName]);
//         });
//       }
//     });
// })();

// export const getFilePathById = (() => {
//   const cache = {};
//   return id =>
//     new Promise(resolve => {
//       if (cache[id]) {
//         resolve(cache[id]);
//       } else if (id) {
//         doRemoteAction("file.path.describe", { id: id }).then(res => {
//           cache[id] = res.path;
//           resolve(cache[id]);
//         });
//       } else {
//         resolve();
//       }
//     });
// })();

export const parseJsonToTable = (name, props, jsonArray, download = false) => {
  const body = jsonArray.map(it => {
    return Object.values(props).map(prop => {
      if (isFunction(prop)) {
        try {
          return prop(it);
        } catch (error) {
          console.log(error);
          return '';
        }
      }
      if (isString(prop)) {
        return get(it, prop, '');
      }
    });
  });
  console.log(body, 111);
  var sheet = XLSX.utils.aoa_to_sheet([Object.keys(props), ...body]);
  const workbook = {
    SheetNames: ['sheet1'], // 总表名
    Sheets: { sheet1: sheet } // test是表名
  };
  var wopts = { bookType: 'xlsx', bookSST: false, type: 'array' };
  if (download) {
    XLSX.writeFile(workbook, `${name}.xlsx`);
  } else {
    var wbout = XLSX.write(workbook, wopts);
    return new Blob([wbout], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  }
};

// /**
//  *
//  * @param {name:string,path:string}[] files
//  */
// export const zipAndDownloadRemoteFile = async (zipName, files) => {
//   const pathMap = await doRemoteAction(
//     "files.path.describe",
//     files.map(it => it.path)
//   );
//   var zip = new JSZip();
//   var img = zip.folder("images");
//   for (let index = 0; index < files.length; index++) {
//     const file = files[index];
//     const { data } = await axios.get(pathMap[file.path], {
//       responseType: "blob"
//     });
//     img.file(file.name, data);
//   }
//   const content = await zip.generateAsync({ type: "blob" });
//   var reader = new FileReader();
//   reader.readAsDataURL(content);
//   reader.onload = function(e) {
//     var a = document.createElement("a");
//     a.download = zipName + ".zip";
//     a.href = e.target.result;
//     a.click();
//   };
// };

// // zipAndDownloadRemoteFile("测试",[{
// //   name:"图片1.jpg",
// //   path: "https://file.lgbfss.com/app_files/aabf29dfb033ec61ca703d6884e05b4b.jpg"
// // }])

export const parseFilePath = url => {
  if (!url) {
    return;
  }
  const paths = url.split('/');
  return {
    url: url,
    name: paths[paths.length - 1]
  };
};
